import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Button } from 'reactstrap';
import { AgentContainer, StyledModal, StyledModalHeader, StyledModalBody, StyledModalFooter } from './ModalConference.styles';
// eslint-disable-next-line no-unused-vars
import AvatarAgent from './AvatarAgent/AvatarAgent';
import AvatarAgent2D from './AvatarAgent/AvatarAgent2D';
import { MeetingProvider } from './MeetingContext';
import DeepgramPollyMic from './Mics/DeepgramPollyMic';
import VadGoogleMic from './Mics/VadGoogleMic';
// import VadGoogleMicStream from './Mics/VadGoogleMicStream';
// import VadElevenLabsMicStream from './Mics/VadElevenLabsMicStream';
// import ElevenLabsMicStream from './Mics/ElevenLabsMicStream';
import DeepgramElevenLabsMic from './Mics/DeepgramElevenLabsMic';
import VadDeepgramElevenLabsMic from './Mics/VadDeepgramElevenLabsMic';
import VadDeepgramElevenLabsMicStream from './Mics/VadDeepgramElevenLabsMicStream';
import VadOpenaiStream from './Mics/VadOpenaiStream';
import MillisMic from './Mics/MillisMic';
import useStore from '../../store';
import { getClientSetting } from '../../utils/featureFlags';
import { useMeeting } from './MeetingContext';
// import AudioStream from './AudioStream';

const AUTO_PLAY_HELLO = false;

const ModalConference = ({ isOpen, toggle, title, agents = [] }) => {
  return (
    <MeetingProvider>
      <ModalConferenceConent isOpen={isOpen} toggle={toggle} title={title} agents={agents} />
    </MeetingProvider>
  );
};

const ModalConferenceConent = ({ isOpen, toggle, title, agents = [] }) => {
  const { activeSpeakerId, setActiveSpeakerId } = useMeeting();
  const [transcript, setTranscript] = useState('');
  const { language } = useStore((state) => ({ language: state.language }));
  const modalRefs = useMemo(() => {
    const refs = {};
    const configs = window.agent_configs || [];
    agents.forEach(agent => {
      refs[agent.id] = React.createRef();
      // Set voices
      const config = configs.find((c) => c.id === agent.image_id);
      // Polly (no good Swedish voices)
      if (config.voice === 'M' && getClientSetting('MODAL_CONFERENCE') === 'DEEPGRAM_POLLY') agent.voice = 'Stephen'; // Only one Swedish neural voice ad female: Elin
      if (config.voice === 'F' && getClientSetting('MODAL_CONFERENCE') === 'GOOGLE') agent.voice = 'Ruth';
      // Elevenlabs
      if (config.voice === 'M' && getClientSetting('MODAL_CONFERENCE') === 'ELEVENLABS_STREAM' && language === 'sv') agent.voice = 'x0u3EW21dbrORJzOq1m9'; // Adam
      if (config.voice === 'F' && getClientSetting('MODAL_CONFERENCE') === 'ELEVENLABS_STREAM' && language === 'sv') agent.voice = '4xkUqaR9MYOJHoaC1Nak'; // Sanna
      if (config.voice === 'M' && getClientSetting('MODAL_CONFERENCE') === 'ELEVENLABS_STREAM' && language === 'en') agent.voice = 'RPEIZnKMqlQiZyZd1Dae'; // Cristopher
      if (config.voice === 'F' && getClientSetting('MODAL_CONFERENCE') === 'ELEVENLABS_STREAM' && language === 'en') agent.voice = 'CoJTolOgtIv629HNctrE'; // Nancy
      // Millis
      const ELEVENLABS_MALES = [
        "29vD33N1CtxCmqQRPOHJ", "5Q0t7uMcjvnagumLfvZi", "CYw3kZ02Hs0563khs1Fj", "ErXwobaYiN019PkySvjV",
        "IKne3meq5aSn9XLyUdCD", "JBFqnCBsd6RMkjVDRZzb",
        /* NOT TESTED: "MF3mGyEYCl7XYWbV9V6O", "N2lVS1w4EtoT3dr4eOWO", "ODq5zmih8GrVes37Dizd",
        "SOYHLrjzK2X1ezoPC6cr", "TX3LPaxmHKxFdv7VOQHJ", "ThT5KcBeYPX3keUQqHPh", "TxGEqnHWrfWFTfGW9XjX",
        "VR6AewLTigWG4xSOukaG", "Xb7hH8MSUJpSbSDYk0k2", "ZQe5CZNOzWyzPSCn5a3c", "Zlb1dXrM653N07WRdFW3",
        "bVMeCyTHy58xNoL34h3p", "flq6f7yk4E4fJM5XTYuZ", "g5CIjZEefAph4nQFvHAz", "iP95p4xoKVk53GoZ742B",
        "nPczCjzI2devNBz1zQrb", "onwK4e9ZLuTAKqWW03F9", "pNInz6obpgDQGcFmaJgB", "pqHfZKP75CvOlQylNhV4",
        "yoZ06aMxZJJ28mfd3POQ", "zcAOhNBS3c14rBihAFp1" */
      ]; // "2EiwWnXFnvU5JabPnv8n","D38z5RcWu1voky8WS1ja",  "GBv7mTt0atIp3Br8iCZE",
      const ELEVENLABS_FEMALES = [
        "21m00Tcm4TlvDq8ikWAM", "EXAVITQu4vr4xnSDxMaL", "XrExE9yKIg1WjnnlVkGX",
        "pFZP5JQG7iQjIQuC4Bku", "z9fAnlkpzviPz146aGWa"
      ]; // "t0jbNlBVZ17f02VDIeMI", "pMsXgVXv3BLzUgSXRplE", "zrHiDhphv9ZnVXBqCLjz", "AZnzlk1XvdvUeBnXmlld",  "LcfcDJNUP1GQjkzn1xUU", "jBpfuIE2acCO8z3wKNLl",
      // "jsCqWAovK2LkecY7zXl4","oWAxZDx7w5VEj9dCyTzz",  "piTKgcLEGmPE4e6mEKli",
      if (config.voice === 'M' && getClientSetting('MODAL_CONFERENCE') === 'MILLIS') {
        const randomIndex = Math.floor(Math.random() * ELEVENLABS_MALES.length);
        agent.voice = ELEVENLABS_MALES[randomIndex];
      }
      if (config.voice === 'F' && getClientSetting('MODAL_CONFERENCE') === 'MILLIS') {
        const randomIndex = Math.floor(Math.random() * ELEVENLABS_FEMALES.length);
        agent.voice = ELEVENLABS_FEMALES[randomIndex];
      }
      // NOTE: Google current handles voice in view, TODO: move here
      console.log('Agent voice selected', agent.voice);
    });
    return refs;
  }, [agents, language]);

  const onTalkDone = useCallback(() => {
    console.log('onTalkDone');
  }, []);

  useEffect(() => {
    if (!AUTO_PLAY_HELLO) return;
    if (modalRefs.length === 0) return;

    const displayAvatars = () => {
      // Get all agent IDs from the modalRefs object
      const agentIds = Object.keys(modalRefs);
      // Ensure that there is at least one agent ID and its ref is currently linked to a DOM element
      if (agentIds.length > 0 && modalRefs[agentIds[0]].current) {
        console.log('Playing message stream');
        modalRefs[agentIds[0]].current.playPollyMessageStream('Hello, how can I help you?');
      }
    };

    if (isOpen) {
      setTimeout(() => {
        displayAvatars();
      }, 500);
    }
  }, [isOpen, modalRefs]);// Depend on isOpen and avatarsLoaded to ensure avatars are displayed correctly

  const playPollyMessageStream = useCallback((agent_id, message) => {
    const agentRef = modalRefs[agent_id];
    if (agentRef && agentRef.current) {
      agentRef.current.playPollyMessageStream(message);
    }
  }, [modalRefs]);

  const playGoogleMessage = useCallback((agent_id, audioUrl, visemes) => {
    const agentRef = modalRefs[agent_id];
    if (agentRef && agentRef.current) {
      agentRef.current.playGoogleMessage(audioUrl, visemes);
    }
  }, [modalRefs]);

  const playElevenlabsAlignments = useCallback((agent_id, alignments) => {
    const agentId = agent_id || Object.keys(modalRefs)[0];
    const agentRef = modalRefs[agentId];
    if (agentRef && agentRef.current) {
      console.log('playElevenlabsAlignments', alignments);
      agentRef.current.playElevenlabsAlignments(alignments);
    }
  }, [modalRefs]);

  const playMillisMessage = useCallback((agent_id, alignments) => {
    const agentId = agent_id || Object.keys(modalRefs)[0];
    const agentRef = modalRefs[agentId];
    if (agentRef && agentRef.current) {
      agentRef.current.playMillisMessage(alignments);
    }
  }, [modalRefs]);

  const stopTalking = useCallback((agent_id) => {
    const agentId = agent_id || Object.keys(modalRefs)[0];
    const agentRef = modalRefs[agentId];
    if (agentRef && agentRef.current) {
      console.log('STOP TALKING', agentRef);
      agentRef.current.stopTalking();
    }
  }, [modalRefs]);

  const firstAgent = agents[0];
  useEffect(() => {
    if (agents && agents.length > 0 ) {
      console.log('Setting active speaker to', agents[0].id);
      setActiveSpeakerId(agents[0].id);
    }
  }, [agents, setActiveSpeakerId]);

  // Update the handleAudioAnalysis callback
  const handleAudioAnalysis = useCallback((agent_id, analyzerNode) => {
    const agentRef = modalRefs[agent_id];
    if (agentRef?.current?.visualization) {
      agentRef.current.visualization.setAnalyzer(analyzerNode);
    }
  }, [modalRefs]);

  const vadOpenaiRef = useRef();

  const changeSpeaker = useCallback(() => {
    stopTalking(); // Stop any ongoing speech
    vadOpenaiRef.current?.changeSpeaker();
  }, [stopTalking]);

  if (!agents || agents.length === 0) return null;

  return (
      <StyledModal isOpen={isOpen} toggle={toggle}>
        <StyledModalHeader toggle={toggle}>{title}</StyledModalHeader>
        <StyledModalBody>
          <AgentContainer>
            {agents && agents.map((agent, index) => (
              <AvatarAgent2D
                key={`avatar-${agent.id}`}
                agent={agent}
                ref={modalRefs[agent.id]}
                onTalkDone={onTalkDone} 
              />
            ))}
          </AgentContainer>
        </StyledModalBody>
        <StyledModalFooter>
          {getClientSetting('MODAL_CONFERENCE') === 'DEEPGRAM_POLLY' && <DeepgramPollyMic isOpen={isOpen} setTranscript={setTranscript} playPollyMessageStream={playPollyMessageStream} />}
          {getClientSetting('MODAL_CONFERENCE') === 'GOOGLE' && <VadGoogleMic isOpen={isOpen} setTranscript={setTranscript} playGoogleMessage={playGoogleMessage} stopTalking={stopTalking} />}
          {/* {getClientSetting('GOOGLE_STREAM && <VadGoogleMicStream isOpen={isOpen} setTranscript={setTranscript} playGoogleMessage={playGoogleMessage} stopTalking={stopTalking} />} */}
          {/* {getClientSetting('VAD_ELEVENLABS_STREAM && <VadElevenLabsMicStream isOpen={isOpen} setTranscript={setTranscript} stopTalking={stopTalking} playElevenlabsAlignments={playElevenlabsAlignments} />} */}
          {/* getClientSetting('ELEVENLABS_STREAM && <ElevenLabsMicStream isOpen={isOpen} setTranscript={setTranscript} stopTalking={stopTalking} playElevenlabsAlignments={playElevenlabsAlignments} />} */}
          {getClientSetting('MODAL_CONFERENCE') === 'DEEPGRAM_ELEVENLABS' && <DeepgramElevenLabsMic isOpen={isOpen} setTranscript={setTranscript} stopTalking={stopTalking} playElevenlabsAlignments={playElevenlabsAlignments} />}
          {getClientSetting('MODAL_CONFERENCE') === 'VAD_DEEPGRAM_ELEVENLABS' && <VadDeepgramElevenLabsMic agent={firstAgent} isOpen={isOpen} setTranscript={setTranscript} stopTalking={stopTalking} playElevenlabsAlignments={playElevenlabsAlignments} />}
          {getClientSetting('MODAL_CONFERENCE') === 'VAD_DEEPGRAM_ELEVENLABS_STREAM' && <VadDeepgramElevenLabsMicStream isOpen={isOpen} setTranscript={setTranscript} stopTalking={stopTalking} playElevenlabsAlignments={(alignment) => playElevenlabsAlignments(firstAgent.id, alignment)} />}
          {getClientSetting('MODAL_CONFERENCE') === 'VAD_OPENAI_STREAM' && activeSpeakerId && (
            <>
              {/*<Button 
                color="warning" 
                className="mb-2"
                onClick={changeSpeaker}
              >
                Change Speaker
              </Button>*/}
              <VadOpenaiStream 
                ref={vadOpenaiRef}
                isOpen={isOpen} 
                setTranscript={setTranscript} 
                stopTalking={stopTalking} 
                playElevenlabsAlignments={(alignment) => playElevenlabsAlignments(firstAgent.id, alignment)}
                onAudioAnalysis={(analyzer) => {
                  console.log('Audio analyzer created');
                  handleAudioAnalysis(firstAgent.id, analyzer);
                }}
              />
            </>
          )}
          {getClientSetting('MODAL_CONFERENCE') === 'MILLIS' && <MillisMic agent={firstAgent} isOpen={isOpen} setTranscript={setTranscript} stopTalking={stopTalking} playMillisMessage={playMillisMessage} />}
          <div className='d-flex w-100 justify-content-center align-items-center text-center'>{transcript}</div>
        </StyledModalFooter>
      </StyledModal>
  );
};

export default ModalConference;
