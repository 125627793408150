import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButton } from './TopicButtons.styles.js';
//import { UncontrolledPopover, PopoverBody } from 'reactstrap';

function TopicButtons({ newChatTopic, viewChatTopics }) {
  const { t } = useTranslation();

  return (
    <div className='d-flex gap-1'>
      <CustomButton id="newChatTopicButton" size="sm" onClick={newChatTopic}>
        <span className="bi bi-pencil-square"></span>
        &nbsp;
        {t('New topic')}
      </CustomButton>
      <CustomButton id="viewChatTopicsButton" size="sm" onClick={viewChatTopics}>
        <span className="bi bi-arrow-counterclockwise"></span>
      </CustomButton>
    </div>
  );
}

export default TopicButtons;
