import React, { useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Button } from 'reactstrap';
import { RealtimeClient } from '@openai/realtime-api-beta';
import { useMeeting } from '../MeetingContext';
import useStore from '../../../store';

const VadOpenaiStream = forwardRef(({ isOpen, setTranscript, stopTalking, onAudioAnalysis }, ref) => {
  const clientRef = useRef(new RealtimeClient({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowAPIKeyInBrowser: true,
    debug: false
  }));

  const { activeSpeakerId, setActiveSpeakerId, setIsChangingSpeaker } = useMeeting();
  const { language, agents } = useStore();

  const mediaStreamRef = useRef(null);
  const audioContextRef = useRef(null); 
  const processorRef = useRef(null);
  const micEnabledRef = useRef(true);
  const isConnectedRef = useRef(false);
  const mountedRef = useRef(true);
  const playbackContextRef = useRef(null);
  const nextPlayTimeRef = useRef(0);
  const recentTranscriptRef = useRef('');
  const audioAnalyzerRef = useRef(null);
  const processingAudioRef = useRef(false);

  const getAgentSettings = (agent) => {
    const instructions = `You are sitting in a conference call. The user is your boss.
    Your name is ${agent.name}.
    
    ${agent.description}
    
    Your default language is ${language}.
    `

    const MALES = ["alloy", "ash", "ballad", "echo"];
    const FEMALES = ["shimmer", "verse", "sage", "coral"];
    const voices = agent.voice === 'M' ? MALES : FEMALES;
    agent.voice = voices[Math.floor(Math.random() * voices.length)];

    const settings = { 
      instructions,
      voice: agent.voice,
      turn_detection: { type: 'server_vad' },
      input_audio_transcription: { model: 'whisper-1' }
    };
    return settings;
  };

  useEffect(() => {
    const client = clientRef.current;
    const mounted = mountedRef.current;

    async function init() {
      try {
        // Set up client parameters
        const agent = agents.find(a => a.id === activeSpeakerId);
        console.log('👀 Using agent:', agent, activeSpeakerId);
        client.updateSession(getAgentSettings(agent));

        // Connect to Realtime API
        await client.connect();

        isConnectedRef.current = true;
        processingAudioRef.current = true;
        console.log('Connected to OpenAI Realtime API');

        console.log('👀 Client is connected:', client.isConnected());
        // const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
        // await sleep(5000);

        // Create a single AudioContext with exact sample rate
        playbackContextRef.current = new (window.AudioContext || window.webkitAudioContext)({
          sampleRate: 24000  // Must match OpenAI's sample rate
        });

        // Add analyzer node setup
        audioAnalyzerRef.current = playbackContextRef.current.createAnalyser();
        audioAnalyzerRef.current.fftSize = 2048;
        audioAnalyzerRef.current.connect(playbackContextRef.current.destination);

        // Send analyzer to AvatarAgent via callback
        if (onAudioAnalysis) {
          onAudioAnalysis(audioAnalyzerRef.current);
        }

        // Set up event handlers
        client.on('conversation.updated', (updated) => {
          if (!mounted) return;
          
          const { item, delta } = updated;
          // console.log('Conversation updated:', updated);
          
          if (delta?.audio) {
            // console.log('Received audio chunk:', delta.audio.length);
            const audioContext = playbackContextRef.current;
            const currentTime = audioContext.currentTime;
            
            // Convert Int16Array to Float32Array for Web Audio API
            const float32Data = new Float32Array(delta.audio.length);
            for (let i = 0; i < delta.audio.length; i++) {
              float32Data[i] = delta.audio[i] / 32768.0;  // Convert from Int16 to Float32
            }
            
            const audioBuffer = audioContext.createBuffer(1, float32Data.length, 24000);
            audioBuffer.getChannelData(0).set(float32Data);
            
            const source = audioContext.createBufferSource();
            source.buffer = audioBuffer;
            source.connect(audioAnalyzerRef.current);
            
            // Schedule the next chunk to play immediately after the previous one
            const startTime = Math.max(currentTime, nextPlayTimeRef.current);
            source.start(startTime);
            nextPlayTimeRef.current = startTime + audioBuffer.duration;
          }

          if (delta?.transcript) {
            // console.log('Transcript update:', delta.transcript, 'from:', item.role);
            if (item.role === 'assistant') {
              // Check if this is the start of a new sentence (starts with capital letter)
              if (delta.transcript.match(/^[A-ZÅÄÖ]/)) {
                // New sentence starting - clear the old one
                recentTranscriptRef.current = delta.transcript;
                setTranscript(recentTranscriptRef.current);
              } else {
                // Continue current sentence
                recentTranscriptRef.current += delta.transcript;
                setTranscript(recentTranscriptRef.current);
              }
            }
          }
        });

        client.on('error', (error) => {
          console.error('OpenAI client error:', error);
        });

        client.on('conversation.interrupted', () => {
          console.log('Conversation interrupted');
          recentTranscriptRef.current = '';
          setTranscript('');
        });

        client.on('server.session.updated', (event) => {
            console.log('👀 Session updated successfully:', event);
            // Handle the successful update here
        });
        /* 
        client.on('realtime.event', (event) => {
          const blackList = ['input_audio_buffer.append'];
          if (blackList.includes(event.event.type)) {
            return;
          }
          console.log('👀 Realtime event:', event.event.type);
          if (event.event.type === 'session.update') {
            console.log('👀 Session updated:', event.event);
          }
        });*/

        // Add weather tool
        client.addTool(
          {
            name: 'get_weather',
            description: 'Retrieves the weather for a given lat, lng coordinate pair.',
            parameters: {
              type: 'object',
              properties: {
                lat: { type: 'number', description: 'Latitude' },
                lng: { type: 'number', description: 'Longitude' },
                location: { type: 'string', description: 'Name of the location' }
              },
              required: ['lat', 'lng', 'location']
            }
          },
          async ({ lat, lng, location }) => {
            console.log('Weather tool called with:', { lat, lng, location });
            try {
              const result = await fetch(
                `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lng}&current=temperature_2m,wind_speed_10m`
              );
              const json = await result.json();
              console.log('Weather data received:', json);
              return json;
            } catch (error) {
              console.error('Weather tool error:', error);
              throw error;
            }
          }
        );

        // Add tool event handlers
        client.on('conversation.item.completed', ({ item }) => {
          if (item.type === 'function_call') {
            console.log('Function call completed:', item);
          }
        });

        // Get microphone stream
        const stream = await navigator.mediaDevices.getUserMedia({ 
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true
          } 
        });
        mediaStreamRef.current = stream;
        console.log('Microphone initialized');

        // Set up audio context and processor
        const context = new (window.AudioContext || window.webkitAudioContext)({
          sampleRate: 24000
        });
        audioContextRef.current = context;
        
        const source = context.createMediaStreamSource(stream);
        const processor = context.createScriptProcessor(2048, 1, 1);
        processorRef.current = processor;

        // Handle audio processing
        processor.onaudioprocess = (e) => {
          if (!micEnabledRef.current || !isConnectedRef.current || !processingAudioRef.current) return;

          try {
            const inputData = e.inputBuffer.getChannelData(0);
            const int16Data = new Int16Array(inputData.length);
            for (let i = 0; i < inputData.length; i++) {
              int16Data[i] = Math.max(-32768, Math.min(32767, inputData[i] * 32768));
            }
            
            if (clientRef.current?.isConnected()) {
              clientRef.current.appendInputAudio(int16Data);
            }
          } catch (error) {
            console.error('Audio processing error:', error);
          }
        };

        // Connect audio nodes
        source.connect(processor);
        processor.connect(context.destination);
        console.log('Audio pipeline ready');

        // Test the connection immediately
        /*console.log('Testing connection...');
        await client.sendUserMessageContent([{ 
          type: 'input_text', 
          text: 'Hello, can you hear me?' 
        }]);
        console.log('Test message sent');*/

        // Add audio stream handler with more logging
        client.on('audio_stream', (audioChunk) => {
          console.log('Received audio stream chunk, length:', audioChunk.length);
          if (!mounted) return;
          
          try {
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const audioBuffer = audioContext.createBuffer(1, audioChunk.length, 24000);
            audioBuffer.getChannelData(0).set(new Float32Array(audioChunk));
            
            const source = audioContext.createBufferSource();
            source.buffer = audioBuffer;
            source.connect(audioContext.destination);
            source.start(0);
            console.log('Started playing audio chunk');
          } catch (error) {
            console.error('Error playing audio chunk:', error);
          }
        });

        // Add error and message handlers
        client.on('error', (error) => {
          console.error('OpenAI client error:', error);
        });

        client.on('message', (message) => {
          console.log('Received message:', message);
        });

        client.on('disconnect', () => {
          isConnectedRef.current = false;
          console.log('Client disconnected');
        });

        /*console.log('Testing weather tool...');
        await client.sendUserMessageContent([{ 
          type: 'input_text', 
          text: 'What is the current weather in New York City? Use the weather tool with coordinates 40.7128, -74.0060.' 
        }]);*/
        await clientRef.current.sendUserMessageContent([{ 
          type: 'input_text', 
          text: 'Say hello and ntroduce yourself in one sentence. Speak in your default language.' 
        }]);

      } catch (error) {
        console.error('Error initializing:', error);
        isConnectedRef.current = false;
        processingAudioRef.current = false;
      }
    }

    init();

    // Clean up on unmount
    return () => {
      mountedRef.current = false;
      const cleanup = async () => {
        processingAudioRef.current = false;
        if (processorRef.current) {
          processorRef.current.disconnect();
        }
        if (mediaStreamRef.current) {
          mediaStreamRef.current.getTracks().forEach(track => track.stop());
        }
        if (audioContextRef.current?.state !== 'closed') {
          await audioContextRef.current?.close();
        }
        if (client?.isConnected()) {
          await client.disconnect();
        }
        if (playbackContextRef.current?.state !== 'closed') {
          await playbackContextRef.current?.close();
        }
        isConnectedRef.current = false;
      };
      cleanup();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTranscript]);

  // Clear transcript when component unmounts or conversation stops
  useEffect(() => {
    return () => {
      setTranscript([]);  // Clear on unmount
    };
  }, [setTranscript]);

  const toggleMic = () => {
    micEnabledRef.current = !micEnabledRef.current;
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getAudioTracks().forEach(track => {
        track.enabled = micEnabledRef.current;
      });
    }
  };

  const stop = async () => {
    if (clientRef.current?.isConnected()) {
      await clientRef.current.disconnect();
    }
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach(track => track.stop());
    }
    if (processorRef.current) {
      processorRef.current.disconnect();
    }
    if (audioContextRef.current) {
      await audioContextRef.current.close();
    }
    isConnectedRef.current = false;
    setTranscript([]); // Clear transcript when stopping
    stopTalking();
  };

  const changeSpeaker = async (speakerId = null) => {
    try {
      if (speakerId) setActiveSpeakerId(speakerId);
      setIsChangingSpeaker(true);
      // Temporarily disable audio processing
      processingAudioRef.current = false;
      
      // 1. Disconnect and clean up existing conversation
      await clientRef.current.disconnect();
      
      // 2. Wait a brief moment to ensure cleanup
      await new Promise(resolve => setTimeout(resolve, 100));
      
      // 3. Reconnect with new settings
      const agent = agents.find(a => a.id === activeSpeakerId);
      const settings = getAgentSettings(agent);
      await clientRef.current.updateSession(settings);
      await clientRef.current.connect();
      
      // Clear current transcript
      recentTranscriptRef.current = '';
      setTranscript('');

      // Send initial message
      await clientRef.current.sendUserMessageContent([{ 
        type: 'input_text', 
        text: 'Say hello and ntroduce yourself in one sentence. Speak in your default language.' 
      }]);

    } catch (error) {
      console.error('Error changing speaker:', error);
    } finally {
      // Reset all flags in finally block to ensure they are always updated
      processingAudioRef.current = true;
      isConnectedRef.current = true;
      setIsChangingSpeaker(false);
    }
  };

  // Simply expose the same function via imperative handle
  useImperativeHandle(ref, () => ({
    changeSpeaker
  }));

  return (
    <div className="d-flex flex-column align-items-center w-100">
      <div className="d-flex justify-content-center align-items-center">
        <Button color="danger" onClick={toggleMic}>
          <span className={`bi bi-mic${micEnabledRef.current ? '' : '-mute'}`}></span>
        </Button>
        {/*<Button onClick={stop}>Stop</Button>
        <Button onClick={changeSpeaker}>New Speaker</Button>*/}
      </div>
    </div>
  );
});

export default VadOpenaiStream;
