import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import ModalBasic from '../../components/ModalBasic/ModalBasic';
import ModalConfirm from '../../components/ModalConfirm/ModalConfirm';
import { Table, Alert, Badge } from 'reactstrap';
import apiService from '../../utils/apiService';
import useStore from '../../store';

function ResourcesTable({ enableSelectedResources = false, selectedResources=[], toggleResourceSelection=() => {} }) {
  const { t } = useTranslation();
  const { agentId, teamId } = useParams();
  const { 
    remResource,
    resources,
    workspace,
    project, 
    document,
    agents,
    teams,
  } = useStore((state) => ({ 
    remResource: state.remResource, 
    resources: state.resources, 
    workspace: state.workspace,
    project: state.project, 
    document: state.document,
    agents: state.agents,
    teams: state.teams,
  }));
  const agent = useMemo(() => agents && agents.find((agent) => agent.id === agentId), [agentId, agents]);
  const team = useMemo(() => teams && teams.find((team) => team.id === teamId), [teamId, teams]);

  const openPicker = async (acceptedFiles) => {
    const metadata = {}
    if (project) {
      metadata.project_id = project.id;
      metadata.document_id = document?.id;
    } else if (agent) {
      metadata.agent_id = agent.id; 
    } else if (team) {
      metadata.team_id = team.id;
    } else {
      console.error('No project, agent or team found');
      return;
    }
    console.log('Uploading with metadata', metadata);
  
    for (const file of acceptedFiles) {
      try {
        console.log('Starting upload process for:', file.name);
        
        // Step 1: Get signed URL
        const getUrlResponse = await apiService.post('/api/resources/get-upload-url/', {
          fileName: file.name,
          contentType: file.type
        });

        if (!getUrlResponse.success) {
          console.error('Failed to get upload URL:', getUrlResponse.message);
          continue;
        }

        const { signedUrl, resourceId, fileName } = getUrlResponse.data;
        console.log('Got signed URL for:', fileName);

        // Step 2: Upload to GCS
        const uploadResponse = await fetch(signedUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type
          }
        });

        if (!uploadResponse.ok) {
          console.error('Failed to upload to GCS:', uploadResponse.statusText);
          continue;
        }

        console.log('File uploaded to GCS, completing process...');

        // Step 3: Complete upload
        const completeResponse = await apiService.post('/api/resources/complete-upload/', {
          resourceId: resourceId,
          fileName: fileName,
          title: file.name,
          metadata: JSON.stringify(metadata)
        });

        if (completeResponse.success) {
          console.log(`File ${file.name} upload completed:`, completeResponse.data);
          // toggleResourceSelection(completeResponse.data.resource_id);
        } else {
          console.error('Failed to complete upload:', completeResponse.message);
        }

      } catch (error) {
        console.error(`Error uploading ${file.name}:`, error);
      }
    }
  }

  const { open } = useDropzone({
    onDrop: openPicker,
  });

   // Functions for Basic Modal
   const [selectedResource, setSelectedResource] = useState(null);
   const [modal, setModal] = useState(false);
   const [modalBody, setModalBody] = useState('');
   const toggleModal = () => {
    setModal(!modal);
  };

  // Functions for Confirm Modal
  const [confirmModal, setConfirmModal] = useState(false);
  const toggleConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  const getStatusLabel = useCallback((status) => {
    /* PENDING = 'PENDING', 'Pending'
    UPLOADING = 'UPLOADING', 'Uploading'
    SUMMARIZING = 'SUMMARIZING', 'Summarizing'
    ANALYZING = 'ANALYZING', 'Analyzing'
    COMPLETE = 'COMPLETE', 'Complete'
    FAILED = 'FAILED', 'Failed'
    IRRELEVANT = 'IRRELEVANT', 'Irrelevant'*/
    switch (status) {
      case 'PENDING':
        return t('Pending');
      case 'UPLOADING':
        return t('Uploading');
      case 'CHUNKING':
        return t('Splitting');
      case 'EMBEDDING':
        return t('Embedding');
      case 'SUMMARIZING':
        return t('Summarizing');
      case 'ANALYZING':
        return t('Analyzing');
      case 'COMPLETE':
        return '';
      case 'FAILED':
        return t('Failed');
      case 'IRRELEVANT':
        return t('Irrelevant');
      default:
        return '';
    }
  }, [t]);

  const getResourceIcon = (resource) => {
    if(resource.status === 'COMPLETE') {
      return <i className="bi bi-file-text"></i>;
    }
    if(['PENDING', 'UPLOADING', 'SUMMARIZING', 'ANALYZING', 'CHUNKING', 'EMBEDDING'].includes(resource.status)) {
      return <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>;
    }
    if(['FAILED', 'IRRELEVANT'].includes(resource.status)) {
      return <i className="bi bi-exclamation-triangle-fill"></i>;
    }
    return <i className="bi bi-file-text"></i>;
  }

  const fetchSummary = useCallback(async (resource) => {
    try {
      const response = await apiService.post(`/api/resources/${resource.id}/summary/`, {});
      if (response.success) {
        setSelectedResource(resource);
        setModalBody(response.data.summary);
        setModal(true);
        console.log('Summary loaded successfully', response.data);
      } else {
        console.error('Summary failed:', response.message);
      }
    } catch (error) {
      console.error('Error loading summary:', error);
    }
  }, []);

  const handleDelete  = useCallback( async (resourceId) => {
    console.log('Deleting resource with id:', resourceId);
    try {
      const response = await apiService.delete(`/api/resources/${resourceId}/delete/`);
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
      console.log('Resource deleted successfully');
      remResource(resourceId);
    } catch (error) {
      console.error('Error during operation:', error);
    }
  }, [remResource]);

  const showDeleteConfirm = (deleteResource) => {
    setSelectedResource(deleteResource);
    setConfirmModal(true);
  }

  const createTable = useCallback((title, renderResources, displayUploadButton = false) => {
    return (<>
      <Table className="mt-2 table table-hover">
        <thead>
          <tr>
            <th>{title}</th>
          </tr>
        </thead>
        <tbody>
          {(!renderResources || renderResources.length === 0) && <tr><td colSpan="2"><Alert color="warning" className='mb-0 py-2'><small><i className="bi bi-exclamation-triangle-fill"></i> {t('No resources have been added yet.')}</small></Alert></td></tr>}
          {renderResources && renderResources.map((resource, index) => (
            <tr
              key={`resource-${index}`}
              onClick={() => {if(!enableSelectedResources){fetchSummary(resource)} else {toggleResourceSelection(resource.id)}}}
              style={{ cursor: 'pointer', backgroundColor: selectedResource === resource ? '#f0f0f0' : 'transparent' }}
            >
              <td className='p-1 d-flex justify-content-between align-items-center'>
              {enableSelectedResources && (
                  <input
                    type="checkbox"
                    checked={selectedResources.includes(resource.id)}
                    onChange={(e) => {
                      e.stopPropagation();
                      toggleResourceSelection(resource.id);
                    }}
                    onClick={(e) => e.stopPropagation()}
                    readOnly
                    className='me-2'
                  />
              )}
                <small className='flex-grow-1'>
                  {getResourceIcon(resource)}
                  &nbsp;
                  {resource.title}
                </small>
                <Button color="link" size="sm" className="trash-button py-1 px-2" onClick={(e) => {e.stopPropagation();showDeleteConfirm(resource)}}>
                  <i className="bi bi-trash"></i>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>  
      </Table>
      
      {displayUploadButton && <>
          <Button outline size="sm" onClick={open}><i className="bi bi-upload"></i> {t('Upload')}</Button>
        </>}
    </>
  )
  }, [t, selectedResource, fetchSummary, open, enableSelectedResources, selectedResources, toggleResourceSelection]);

  
  const workspaceTable = useMemo(() => createTable(<span>{workspace?.name}</span>, resources, false), [createTable, workspace?.name, resources]);
  const projectTable = useMemo(() => {
    if(!project) return null;
    return createTable(
      <span>{project.name}</span>,
      project.resources,
      true
    );
  }, [createTable, project]);
  const agentTable = useMemo(() => {
    if(!agent) return null;
    return createTable(
      <span>{agent.name}</span>,
      agent.resources,
      true
    );
  }, [createTable, agent]);
  const teamTable = useMemo(() => {
    if(!team) return null;
    return createTable(
      <span>{team.name}</span>,
      team.resources,
      true
    );
  }, [createTable, team]);


  const getTruncatedName = (name, maxLength) => {
    if (!name || name.length <= maxLength) return name;
    return name.substring(0, maxLength) + '...';
  }
  // Get available tables (move this before the activeTab state)
  const tables = [
    { id: '1', content: workspaceTable, title: t('Workspace'), show: true },
    { id: '2', content: projectTable, title: project && project.super_type === "WORKFLOW" ? t('Workflow') : t('Project'), show: !!project },
    { id: '3', content: agentTable, title: <span>{getTruncatedName(agent?.name, 21)}</span>, show: !!agent },
    { id: '4', content: teamTable, title: <span>{getTruncatedName(team?.name, 21)}</span>, show: !!team }
  ].filter(table => table.show);

  // Set initial active tab to the last available table
  const [activeTab, setActiveTab] = useState(tables[tables.length - 1]?.id || '1');

  // Add this toggle function for tabs
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  if (!workspace) return null;

  return (
    <>
      {tables.length > 1 ? (
        <>
          <Nav tabs>
            {tables.map(table => (
              <NavItem key={table.id}>
                <NavLink
                  className={activeTab === table.id ? 'active' : ''}
                  onClick={() => toggleTab(table.id)}
                >
                  {table.title}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            {tables.map(table => (
              <TabPane key={table.id} tabId={table.id}>
                {table.content}
              </TabPane>
            ))}
          </TabContent>
        </>
      ) : (
        tables[0].content
      )}
      <ModalBasic isOpen={modal} toggle={toggleModal} title={selectedResource && `${t('Summary')} - ${selectedResource.title}`}>
        <Markdown remarkPlugins={[remarkGfm]}>{modalBody}</Markdown>
      </ModalBasic>
      <ModalConfirm isOpen={confirmModal} toggle={toggleConfirmModal} title={t('Delete Resource')} onConfirm={() => handleDelete(selectedResource.id)} confirmButtonColor='danger'>
        <strong>{t('Are you sure you want to delete this resource?')}</strong>
        <br/>
        <br/>
        {selectedResource && selectedResource.title}
      </ModalConfirm>
    </>
  );
}

export default ResourcesTable;
