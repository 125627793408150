import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import apiService from '../../utils/apiService';
import TeamDropDown from '../../components/TeamDropDown/TeamDropDown';
import { ButtonFlexContainer, StyledActionButton } from '../../components/DocumentTemplate/ChatBotGuided/ChatBotGuided.styles';
import useStore from '../../store';
import { StyledHeader, StarButton } from './TeamPage.styles';
import Team from '../../components/Team/Team';
import FlexLoader from '../../components/FlexLoader/FlexLoader';
import ResourcesWidget from '../../components/ResourcesWidget/ResourcesWidget';
import { useModalConference } from '../../contexts/ModalConferenceContext';
import Chat from '../../components/Chat/Chat';
import ChatForm from '../../components/ChatForm/ChatForm';
import AgentsWidget from '../../components/AgentsWidget/AgentsWidget';
import WorkflowsWidget from '../../components/WorkflowsWidget/WorkflowsWidget';
import { useModalCreateWorkflow } from '../../contexts/ModalCreateWorkflowContext';
import ChatTopicsModal from '../../components/ChatTopicsModal/ChatTopicsModal';

function TeamPage({ scrollToBottom }) {
  const { t } = useTranslation();
  const { teamId  } = useParams();
  const { topic, setTopic, agents, teams, loggedIn, workspace, resources } = useStore((state) => ({
    topic: state.topic,
    setTopic: state.setTopic,
    agents: state.agents,
    teams: state.teams,
    loggedIn: state.loggedIn,
    workspace: state.workspace,
    resources: state.resources,
  }));
  const navigate = useNavigate();

  // Modals
  const { showModal: showWorkflowModal } = useModalCreateWorkflow();
  const { showModal: showConferenceModal } = useModalConference();

  // Define the showCreateWorkflowModal function before it's used
  const showCreateWorkflowModal = (name) => {
    showWorkflowModal({
      workflow_template_name: name,
      isCustom: false,
      type: 'WORKFLOW'
    });
  };

  const [showChatTopicsModal, setShowChatTopicsModal] = useState(false);
  const toggleChatTopicsModal = () => {
    loadChatTopics();
    setShowChatTopicsModal(!showChatTopicsModal);
  };
  const [ topics, setTopics ] = useState([]);
  const loadChatTopics = useCallback(async () => {
      const endpoint = `/api/topic/find/all/`;
      const formData = { team_id: teamId, type: "TEAM" };
      try {
        const response = await apiService.post(endpoint, formData);
        if (response.success) {
          console.log('Chat topics loaded successfully', response.data);
          setTopics(response.data);
        } else {
          console.error('Operation failed:', response.message);
        }
      } catch (error) {
        console.error('Error during operation:', error);
      }
    }, [teamId]);

  useEffect(() => {
    if (!loggedIn) return;
    loadChatTopics();
  }, [loggedIn, teamId, loadChatTopics]);

  // agent is used to get the resources
  // const agent = useMemo(() => agents && agents.find((agent) => agent.id === teamId), [teamId, agents]);
  const team = useMemo(() => teams && teams.find((team) => team.id === teamId), [teamId, teams]);

  const handleActionSubmit = async (e, action) => {
    e.preventDefault();
    const newTopic = {
      ...topic,
      chat_history: [...topic.chat_history, { type: 'USER', message: action.prompt }],
    }
    setTopic(newTopic);
    const endpoint = `/api/topic/${topic.id}/`;
    const formData = {
      user_input: action.prompt,
    };
    try {
      const response = await apiService.post(endpoint, formData);
      if (response.success) {
        console.log('Action message sent successfully', response.data.job);
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    } finally {
      // setIsSending(false); // Re-enable input
    }
  };

  const teamAgents = useMemo(() => {
    if (!team || !team.agents || !agents) return [];
    const configs = window.agent_configs || [];
    return team.agents.reduce((acc, agentId) => {
      const agent = agents.find((a) => a.id === agentId);
      if (agent) {
        const config = configs.find((c) => c.id === agent.image_id);
        const voice = config ? (config.voice === 'M' ? 'Stephen' : 'Ruth') : 'Stephen';
        acc.push({
          ...agent,
          voice,
        });
      } else {
        console.warn(`Agent with ID ${agentId} not found.`);
      }
      return acc;
    }, []);
  }, [team, agents]);


  const startMeeting = async () => {
    showConferenceModal(`Meeting with ${team.name}`, teamAgents);
  }

  const handlePromoteTeam = async (teamId, isPromote) => {
    if (!teamId) {
      console.error('Team ID is missing:', teamId);
      return;
    }
    try {
      const response = await apiService.post(`/api/teams/${teamId}/${isPromote ? 'promote' : 'demote'}/`);
      if (!response.success) {
        console.error('Operation failed:', response.message);
        return;
      }
    } catch (error) {
      console.error('handlePromoteTeam error', error);
    }
  };


  const handleChatTopicDelete = async (topicId) => {
    // Optimistically update the UI
    const previousTopics = [...topics];
    setTopics(topics.filter((topic) => topic.id !== topicId));
    try {
      const response = await apiService.delete(`/api/topic/${topicId}/delete/`);
      if (response.success) {
        console.log('Chat topic deleted successfully', response.data);
        console.log(topicId, topic?.id, topicId === topic?.id);
        if (topicId === topic?.id) {
          const targetUrl = `/${workspace.id}/team/${teamId}/`;
          if (window.location.pathname === targetUrl) {
            window.location.reload();
          } else {
            navigate(targetUrl);
          }
        }
      } else {
        console.error('Operation failed:', response.message);
        // Revert the UI update if the deletion fails
        setTopics(previousTopics);
      }
    } catch (error) {
      console.error('Error during operation:', error);
      // Revert the UI update if an error occurs
      setTopics(previousTopics);
    }
  };

  const handleChatTopicSelect = async (topicId) => {
    navigate(`/${workspace.id}/team/${teamId}/${topicId}/`);
    toggleChatTopicsModal();
  };

  const handleNewChatTopic = useCallback(async () => {
    try {
      const payload = { team_id: teamId };
      const response = await apiService.post(`/api/topic/new/`, payload);
      if (response.success) {
        console.log('New chat topic created successfully', response.data);
        // Add to setTopics
        setTopics([...topics, response.data]);
        navigate(`/${workspace.id}/team/${teamId}/${response.data.id}/`);
      } else {
        console.error('Operation failed:', response.message);
      }
    } catch (error) {
      console.error('Error during operation:', error);
    }
  }, [teamId, topics, navigate, workspace?.id]);

  const availableResources = useMemo(() => {
    if (!team) return null;
    if (!resources) return null;
    return [...team.resources, ...resources];
  }, [resources, team]);

  if(!topic) return (<FlexLoader />);

  return (
    <>
      <Row className='gx-0'>
        <Col sm="12" md={{ size: 9 }}>
          <StyledHeader className='d-flex align-items-center justify-content-between mb-2'>
            <h1>
              {team?.name}
              &nbsp;
              <StarButton className="star-button" size="sm" onClick={() => handlePromoteTeam(team.id, !team.promoted)}>
              {team?.promoted ? (
                <span className="bi bi-star-fill"></span>
              ) : (
                <span className="bi bi-star"></span>
              )}
            </StarButton>
            </h1>
            {team && <TeamDropDown workspace={workspace} team={team} startMeeting={startMeeting} newChatTopic={handleNewChatTopic} viewChatTopics={toggleChatTopicsModal} />}
          </StyledHeader>
        </Col>
      </Row>
      <Row className='gx-0'>
        <Col sm="12" md={{ size: 9 }}>
          {team && <Team team={team} enableRun={false} showHeader={false} showDropDown={false} handleStartMeeting={startMeeting} />}
          <Chat scrollToBottom={scrollToBottom} />

          {topic && topic.actions && (
            <ButtonFlexContainer className='mb-3'>
              {topic.actions.map((action, index) => (
                <StyledActionButton key={`action-btn-${index}`} color="primary" outline size="sm" onClick={(e) => handleActionSubmit(e, action)}>{t(action.label)}</StyledActionButton>
              ))}
            </ButtonFlexContainer>
          )}
          <ChatForm key={topic?.id} />
        </Col>
        <Col sm="0" md="3" className="d-none d-md-block ps-md-4">
          {team && <ResourcesWidget resources={availableResources} className="mb-4" />}
          <AgentsWidget agents={teamAgents} />
          <WorkflowsWidget workflows={team?.workflows} showCreateWorkflowModal={showCreateWorkflowModal} />
        </Col>
      </Row>
      <ChatTopicsModal
        isOpen={showChatTopicsModal}
        toggle={toggleChatTopicsModal}
        topics={topics}
        onDelete={handleChatTopicDelete}
        onSelect={handleChatTopicSelect}
      />
   </>
  );
}

export default TeamPage;
