import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// import mermaid from 'mermaid';
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import apiService from '../../utils/apiService';

import DocumentAccordian from '../../components/DocumentAccordian/DocumentAccordian';
import DocumentDropDown from '../../components/DocumentDropDown/DocumentDropDown';
import FlexLoader from '../../components/FlexLoader/FlexLoader';
// import DocumentTemplate from '../../components/DocumentTemplate/DocumentTemplate';
import AgentsList from '../../components/AgentsList/AgentsList';
import useStore from '../../store';
import ResourcesWidget from '../../components/ResourcesWidget/ResourcesWidget';
import AgentsWidget from '../../components/AgentsWidget/AgentsWidget';
import { StyledHeader, PrintableArea } from './DocumentPage.styles';

import Workflow from '../../components/DocumentTemplate/Workflow/Workflow';
import Upload from '../../components/DocumentTemplate/Upload/Upload';

function DocumentPage({scrollToBottom}) {
  const { t } = useTranslation();
  const { projectId, documentId } = useParams();
  const [documentAgents, setDocumentAgents] = useState([]);
  // const [documentTaskAgents, setDocumentTaskAgents] = useState([]);

  const { document, project, agents, topic, workspace, resources } = useStore((state) => ({
    project: state.project,
    agents: state.agents,
    document: state.document,
    topic: state.topic,
    workspace: state.workspace,
    resources: state.resources,
  }));


  useEffect(() => {
    if(agents) setDocumentAgents(agents.filter(agent => agent.document_id === documentId));
  }, [agents, documentId]);

  /*useEffect(() => {
    if (document && agents) setDocumentTaskAgents(document.agent_ids.map((agent_id) => agents.find((agent) => agent.id === agent_id)));
  },[document, agents]);*/

  const nextDocument = useMemo(() => {
    if (!project || !project.stages) return null;
    let currentDocumentFound = false;
    for (let stage of project.stages) {
      for (let document of stage.documents) {
        if (currentDocumentFound) return document; // Return the next document after finding the current one
        if (document.id === documentId) currentDocumentFound = true; // Mark current document as found
      }
    }
    return null; // Return null if no next document is found
  }, [project, documentId]);

  const navigate = useNavigate();
  const handleNext = async (e) => {
    e.preventDefault();

    nextDocument.is_generating = true; // Set generating state to true at the start of the operation
    const endpoint = `/api/document/${nextDocument.id}/start/`;
    const formData ={};
    try {
      const response = await apiService.post(endpoint, formData);
      if (response.success) {
        const loadedJob = response.data.job;
        console.log('Document start initiated successfully', loadedJob);
      } else {
        console.error('Operation failed:', response.message);
        document.is_generating = false;
      }
    } catch (error) {
      console.error('Error during operation:', error);
    } finally {
      nextDocument.is_generating = false;
      navigate(`/project/${projectId}/document/${nextDocument.id}`); // Use navigate to change the path
    }
  };

  const markdownFormatted = useMemo(() => {
    // rem ```markdown``` from content
    if(document && document.content) {
      return document.content.replace(/```markdown/g, '').replace(/```/g, '');
    }
    return '';
  }, [document]);
  
  const availableResources = useMemo(() => {
    if (!project) return null;
    if (!resources) return null;
    return [...project.resources, ...resources];
  }, [resources, project]);

  // --------------- Resources ---------------
  const [selectedResources, setSelectedResources] = useState([]);
  const [prevSelectedResources, setPrevSelectedResources] = useState([]);
  const toggleResourceSelection = useCallback((resourceId) => {
    setSelectedResources(prev => 
      prev.includes(resourceId) 
        ? prev.filter(id => id !== resourceId)
        : [...prev, resourceId]
    );
  }, []);
  useEffect(() => {
    if (project && project.selected_resources) {
      setSelectedResources(project.selected_resources);
      setPrevSelectedResources(project.selected_resources);
    } else {
      setSelectedResources([]);
      setPrevSelectedResources([]);
      }
  }, [project]);
  const updateSelectedResources = useCallback(async (selectedResources) => {
    if (JSON.stringify(selectedResources) === JSON.stringify(prevSelectedResources)) {
      return; // No change in selected resources, so no need to update
    }
    try {
      console.log('DEBUG: updateSelectedResources', selectedResources);
      const response = await apiService.post(`/api/workflows/${project.id}/${document.id}/selected-resources/`, { selected_resources: selectedResources });
      if (response.success) {
        console.log('Update selected resources successful:', response);
        setPrevSelectedResources(selectedResources); // Update the previous selected resources
      }
    } catch (error) {
      console.error('Error during Load Teams:', error);
    }
  }, [document?.id, project?.id, prevSelectedResources]);

  useEffect(() => {
    updateSelectedResources(selectedResources);
  }, [selectedResources, updateSelectedResources]);


  // --------------- Agents ---------------
  if(topic && topic.agent_ids) console.log('topic.agent_ids', topic.agent_ids);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [prevSelectedAgents, setPrevSelectedAgents] = useState([]);
  const toggleAgentSelection = useCallback((agentId) => {
    setSelectedAgents(prev => 
      prev.includes(agentId) 
        ? prev.filter(id => id !== agentId)
        : [...prev, agentId]
    );
  }, []);
  useEffect(() => {
    if (topic && topic.agent_ids) {
      setSelectedAgents(topic.agent_ids);
      setPrevSelectedAgents(topic.agent_ids);
    } else {
      setSelectedAgents([]);
      setPrevSelectedAgents([]);
    }
  }, [topic]);
  const updateSelectedAgents = useCallback(async (selectedAgents) => {
    if (JSON.stringify(selectedAgents) === JSON.stringify(prevSelectedAgents)) {
      return; // No change in selected agents, so no need to update
    }
    try {
      console.log('DEBUG: updateSelectedAgents', selectedAgents);
      const response = await apiService.post(`/api/workflows/${project.id}/${document.id}/selected-agents/`, { selected_agents: selectedAgents });
      if (response.success) {
        console.log('Update selected agents successful:', response);
        setPrevSelectedAgents(selectedAgents); // Update the previous selected agents
      }
    } catch (error) {
      console.error('Error during Load Teams:', error);
    }
  }, [document?.id, project?.id, prevSelectedAgents]);
  useEffect(() => {
    updateSelectedAgents(selectedAgents);
  }, [selectedAgents, updateSelectedAgents]);

  const topicAgents = useMemo(() => {
    if (!topic || !agents) return [];
    const configs = window.agent_configs || [];
    return agents.filter(agent => topic.agent_ids.includes(agent.id)).map(agent => {
      const config = configs.find(c => c.id === agent.image_id);
      const voice = config ? (config.voice === 'M' ? 'Stephen' : 'Ruth') : 'Stephen';
      return {
        ...agent,
        voice
      };
    });
  }, [agents, topic]);
  const availableAgents = useMemo(() => {
    if (!topicAgents || !agents) return null;
    // Filter out agents that are type of TEAM or PROJECT from agents
    const filteredAgents = agents.filter(agent => agent.origin !== 'TEAM' && agent.origin !== 'PROJECT');
    const uniqueAgents = [...new Map([...topicAgents, ...filteredAgents].map(agent => [agent.id, agent])).values()];
    return uniqueAgents;
  }, [agents, topicAgents]);


  const allDocuments = useMemo(() => {
    if(!project) return [];
    return project.stages.flatMap(stage => stage.documents);
  }, [project]);

  const isFirstDocument = useMemo(() => {
    if(!allDocuments.length) return false;
    return allDocuments[0].id === documentId;
  }, [allDocuments, documentId]);


  if(!document) return (<FlexLoader />);

  return (
    <>
      <Row className='d-print-none'>
        <Col sm="12" md={{ size: 9 }}>
          <StyledHeader className='d-flex align-items-center justify-content-between'>
            <h1>{document.title}</h1>
            <DocumentDropDown document={document} agents={topicAgents} />
          </StyledHeader>
        </Col>
      </Row>
      {document && document.template_type === "CHATBOT_GUIDED" && !(document.data && document.data.initialized) ? (
        <Row>
          <Col sm="12" md={{ size: 9 }}>
            {!document.is_generating && <div className='alert alert-danger'>{t('You need to summarize and review the prior step.')}</div>}
            {document.is_generating && <div className='alert alert-info'>{t('Preparing...')}</div>}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col sm="12" md={{ size: 9 }}>
            {document ?
              <>
                <div className='d-print-none'>
                  {!['WORKFLOW', 'UPLOAD', 'RESEARCH', 'INTERNET_SEARCH', 'GENERATE', 'FINAL_RESULT'].includes(document.template_type) && <AgentsList agents={documentAgents} workspace={workspace} project={project} />}
                  {!['REPORT'].includes(document.type) && !['WORKFLOW', 'UPLOAD', 'RESEARCH', 'INTERNET_SEARCH', 'GENERATE', 'FINAL_RESULT'].includes(document.template_type) && <DocumentAccordian projectId={projectId} document={document} />}
                  {['WORKFLOW', 'RESEARCH', 'INTERNET_SEARCH', 'GENERATE', 'FINAL_RESULT'].includes(document.template_type) && <Workflow projectId={projectId} document={document} nextDocument={nextDocument} key={`document-workflow-${document.id}`} scrollToBottom={scrollToBottom} />}
                  {document.template_type === 'UPLOAD' && <Upload document={document} key={`document-upload-${document.id}`} handleNext={handleNext} scrollToBottom={scrollToBottom} />}
                </div>
                {!document.is_generating && (
                  <>
                    <PrintableArea id="printableArea">
                      <Markdown  remarkPlugins={[remarkGfm]}>{markdownFormatted}</Markdown>
                    </PrintableArea>
                    {(document.type === 'DYNAMIC_PROJECT' || document.template_type === 'CHATBOT_GUIDED') && document.content && nextDocument &&
                      <Button color="success" size="sm" onClick={handleNext} className='d-print-none'>
                        {nextDocument.is_generating && <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;</>}
                        {project.super_type === "WORKFLOW" && <>{t('START')}</>}
                        {project.super_type === "PROJECT" && <>{t('Approve & Go to Next Step')}</>}
                      </Button>}
                  </>
                )}
              </>
              : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            }
          </Col>
          <Col sm="0" md="3" className="d-none d-md-block d-print-none">
            {document && (document.template_type === 'UPLOAD' || (isFirstDocument && document.template_type === 'CHATBOT_GUIDED')) && <ResourcesWidget resources={availableResources} className="mb-4" enableSelectedResources={true} selectedResources={selectedResources} toggleResourceSelection={toggleResourceSelection} />}
            {availableAgents && <AgentsWidget agents={availableAgents} enableSelectedAgents={true} selectedAgents={selectedAgents} toggleAgentSelection={toggleAgentSelection} />}
          </Col>
        </Row>
      )}
    </>
  );
}

export default DocumentPage;