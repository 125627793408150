import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import ModalBasic from '../ModalBasic/ModalBasic';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const POLLING_INTERVAL = 5 * 60 * 1000; // 5 minutes
const fetcher = (...args) => fetch(...args).then(res => res.json());

function ServerVersionChecker() {
  const [showUpdatePrompt, setShowUpdatePrompt] = useState(false);
  const [currentVersion, setCurrentVersion] = useState(null);
  const { t } = useTranslation();

  const { data } = useSWR('/api/version/', fetcher, {
    refreshInterval: POLLING_INTERVAL,
    revalidateOnFocus: false
  });

  useEffect(() => {
    if (data?.version) {
      if (currentVersion === null) {
        // First load - just store the version
        console.log('First load - just store the version', data.version);
        setCurrentVersion(data.version);
      } else if (currentVersion !== data.version) {
        // Version changed - show update prompt
        console.log('Version changed - show update prompt');
        setShowUpdatePrompt(true);
      }
    }
  }, [data, currentVersion]);

  const footerButtons = (
    <>
      <Button color="primary" onClick={() => window.location.reload()}>
        {t('Refresh Now')}
      </Button>
      <Button color="secondary" onClick={() => setShowUpdatePrompt(false)}>
        {t('Dismiss')}
      </Button>
    </>
  );

  return (
    <ModalBasic
      isOpen={showUpdatePrompt}
      toggle={() => setShowUpdatePrompt(false)}
      title={t('Update Available')}
      size="sm"
      footerButtons={footerButtons}
    >
      {t('A new version is available. Please refresh to update.')}
    </ModalBasic>
  );
}

export default ServerVersionChecker;
