import React, { useState } from 'react';
import { Accordion, AccordionItem, AccordionBody, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { useTranslation } from 'react-i18next';
import { AgentImage, BlinkingCursor, CustomMarkdown, TextButton, CustomAccordionHeader, AccordianStatusText } from '../Chat.styles';
import useStore from '../../../store';
import { useModalSelectRoles } from  '../../../contexts/ModalSelectRoles';
import { useModalTool } from '../../../contexts/ModalToolContext';
import ButtonSmall from '../../ButtonSmall/ButtonSmall';
import { StyledDropdown } from '../Chat.styles';

function AIMessage({ message, expandedReferences, toggleReferences, handleShowReferences, handleActionSubmit, index, handleSaveAsResource = null }) {
  const { t } = useTranslation();
  const { agents } = useStore((state) => ({ agents: state.agents }));
  const [accordionOpen, setAccordionOpen] = React.useState('');

  const { showModal: showModalTool } = useModalTool();
  const { showModal: showSelectRolesModal } = useModalSelectRoles();

  // Function to handle dropdown toggle
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => { setDropdownOpen(prevState => !prevState); };

  const getAgentName = (agentId) => {
    if (!agents || !agentId) return '';
    const agent = agents.find(agent => agent.id === agentId);
    return agent ? agent.name : '';
  };

  const getAgentImage = (agentId) => {
    if (!agents || !agentId) return null;
    const agent = agents.find(agent => agent.id === agentId);
    const imageURL = agent ? agent.image_url : '';
    return <AgentImage $imageURL={imageURL} />;
  };

  const toggleAccordion = (id) => {
    setAccordionOpen(accordionOpen === id ? '' : id);
  };

  const handleSuggestCoWorkers = () => {
    showModalTool(t('Suggest co-workers'), 'SUGGEST_CO_WORKERS', message.agent_id);
  };

  return (
    <>
      <div className="flex-shrink-0">
        {getAgentImage(message.agent_id)}
      </div>
      <div className="flex-grow-1 ms-3">
        <div className='mb-1'><strong>{getAgentName(message.agent_id)}</strong></div>
        {message.data?.sub_type === 'SIMPLE_TASK' ? (
          <Accordion open={accordionOpen} toggle={toggleAccordion} className='mb-2'>
            <AccordionItem>
              <CustomAccordionHeader targetId="1">
                {message.data?.streaming && (
                  <div className="spinner-container me-3">
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                  </div>
                )}
                <AccordianStatusText>{message.data?.status}</AccordianStatusText>
              </CustomAccordionHeader>
              <AccordionBody accordionId="1">
                <AIMessageContent message={message} />
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        ) : message.data?.sub_type?.toUpperCase() === 'LAUNCH_MODAL_WITH_ROLES' ? (
          <>
            <AIMessageContent message={message} />
            <Button className='mb-2' size='sm' color='primary' onClick={() => showSelectRolesModal(message.data.roles)}>{t('Suggested co-workers')}</Button>
          </>
        ) : message.data?.sub_type?.toUpperCase() === 'LIST_OF_PROMPTS' || message.data?.sub_type?.toUpperCase() === 'LIST_OF_FEEDBACK' ? (
          <>
            <AIMessageContent message={message} />
            <div className="mb-2">
              {message.data.questions.map((question, index) => (
                <div className="me-2">
                  {index + 1}.&nbsp;
                  {question}
                  &nbsp;
                  <ButtonSmall 
                    size='sm' 
                    color='primary'
                    outline
                    onClick={() => handleActionSubmit(question)}
                    className="ms-auto"
                    key={index}
                  >
                    <span class="bi bi-chat"></span> {message.data?.sub_type?.toUpperCase() === 'LIST_OF_PROMPTS' ? t('Ask') : t('Agree')}
                  </ButtonSmall>
                </div>
              ))}
            </div>
          </>
        ) : message.data?.sub_type?.toUpperCase() === 'LAUNCH_SUGGEST_ROLES' ? (
          <>
            <AIMessageContent message={message} />
            <Button className='mb-2' size='sm' color='primary' onClick={handleSuggestCoWorkers}>{t('Suggest co-workers')}</Button>
          </>
        ) : (
          <AIMessageContent message={message} />
        )}
        {message.references && (
          <AIMessageReferences
            message={message}
            expandedReferences={expandedReferences}
            toggleReferences={toggleReferences}
            handleShowReferences={handleShowReferences}
            index={index}
          />
        )}
      </div>
      {handleSaveAsResource && message.data?.sub_type !== 'SIMPLE_TASK' && <div>
        <StyledDropdown>
          <Dropdown isOpen={dropdownOpen} toggle={(e) => { e.stopPropagation(); toggle(); }}>
            <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen} onClick={(e) => e.stopPropagation()}>
              <i className="bi bi-three-dots-vertical"></i>
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem onClick={handleSaveAsResource}>{t('Save as resource')}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </StyledDropdown>
      </div>}
    </>
  );
}

function AIMessageContent({ message }) {
  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        span: (props) => {  
          const { className, ...rest } = props;
          if (className === 'cursor') {
            return <BlinkingCursor>▋</BlinkingCursor>;
          } else {
            return <span className={className} {...rest}>{props.children}</span>;
          }
        },
      }}
    >
      {message.data?.streaming ? `${message.message}<span class="cursor"></span>` : message.message}
    </Markdown>
  );
}

function AIMessageReferences({ message, expandedReferences, toggleReferences, handleShowReferences, index }) {
  const { t } = useTranslation();

  return (
    <>
      <div onClick={() => toggleReferences(index)} style={{ cursor: 'pointer', fontWeight: 'bold' }} className='mb-2'>
        {t('References')} <i className={`bi ${expandedReferences[index] ? 'bi-caret-down-fill' : 'bi-caret-right-fill'}`}></i>
      </div>
      {expandedReferences[index] && (
        <>
          <CustomMarkdown remarkPlugins={[remarkGfm]}>{message.references}</CustomMarkdown>
          <ul><li><TextButton size='sm' outline onClick={handleShowReferences}>{t('Show more')}</TextButton></li></ul>
        </>
      )}
    </>
  );
}

export default AIMessage;
