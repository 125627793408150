import React, { useState, useEffect, useCallback } from 'react';
import remarkGfm from 'remark-gfm';
import { useTranslation } from 'react-i18next';
import useStore from '../../store';
import FlexLoader from '../FlexLoader/FlexLoader';
import ModalBasic from '../ModalBasic/ModalBasic';
import MessageItem from './Messages/MessageItem';
import { ReferencesMarkdown } from './Chat.styles';
import apiService from '../../utils/apiService';

function Chat({ scrollToBottom, handleActionSubmit }) {
  const { t } = useTranslation();
  const { topic } = useStore((state) => ({ topic: state.topic }));
  const [expandedReferences, setExpandedReferences] = useState({});
  const [modal, setModal] = useState(false);
  const [modalBody, setModalBody] = useState('');

  const toggleReferences = useCallback((index) => {
    setExpandedReferences((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));

    const latestIndex = topic?.chat_history?.length - 1;
    if (latestIndex === index && scrollToBottom) {
      scrollToBottom();
    }
  }, [topic, scrollToBottom]);

  const handleShowReferences = useCallback(async (e, messageId) => {
    e.preventDefault();
    try {
      const response = await apiService.post(`/api/topic/${topic.id}/message/${messageId}/references/`, {});
      if (response.success) {
        setModalBody(response.data.data);
        setModal(true);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error('Error fetching references:', error);
      setModalBody('Failed to load references. Please try again later.');
      setModal(true);
    }
  }, [topic]);

  const handleSaveAsResource = useCallback(async (e, messageId) => {
    e.preventDefault();
    try {
      const response = await apiService.post(`/api/messages/${messageId}/save-as-resource/`, {});
      if (!response.success) {
        throw new Error(response.message);
      }
      // You might want to show a success notification here
    } catch (error) {
      console.error('Error saving message as resource:', error);
      // You might want to show an error notification here
    }
  }, []);

  useEffect(() => {
    if (topic && topic.chat_history.length > 0) {
      const latestIndex = topic.chat_history.length - 1;
      setExpandedReferences((prev) => ({ ...prev, [latestIndex]: true }));
    }
    if (scrollToBottom) scrollToBottom();
  }, [topic, scrollToBottom]);

  if (!topic) return <FlexLoader />;

  return (
    <>
      {topic.chat_history.map((message, index) => (
        <MessageItem
          key={`message-${index}`}
          message={message}
          index={index}
          isLastMessage={index === topic.chat_history.length - 1}
          expandedReferences={expandedReferences}
          toggleReferences={() => toggleReferences(index)}
          handleShowReferences={(e) => handleShowReferences(e, message.id)}
          handleActionSubmit={handleActionSubmit}
          handleSaveAsResource={(e) => handleSaveAsResource(e, message.id)}
        />
      ))}
      <ModalBasic isOpen={modal} toggle={() => setModal(!modal)} title={t('References')}>
        <div className="modal-body overflow-auto" style={{ maxHeight: '400px' }}>
          <ReferencesMarkdown 
            remarkPlugins={[remarkGfm]} 
            components={{
              img: (props) => <img {...props} style={{ maxWidth: '45%', height: 'auto' }} alt={props.alt} />
            }}
          >
            {modalBody}
          </ReferencesMarkdown>
        </div>
      </ModalBasic>
    </>
  );
}

export default Chat;
